import { Module } from 'vuex';
import to from 'await-to-js';
import moment from 'moment/moment';
import { State } from '@/models/State';
import { bloqifyFirestore, firebase } from '@/boot/firebase';
import { DataContainerStatus } from '@/models/Common';
import { generateState, mutateState, Vertebra } from '@/store/utils/skeleton';
import { Earning, Investment } from '@/models/investments/Investment';
import { Asset } from '@/models/assets/Asset';
import { Dividend } from '@/models/assets/Dividends';
import DocumentReference = firebase.firestore.DocumentReference;
import Timestamp = firebase.firestore.Timestamp;

const SET_DIVIDENDS = 'SET_DIVIDENDS';

export interface UpdateDividendParam {
  newTotalAmount: number,
  assetId: string,
  dividendId: string,
  period: Date,
}

export interface DeleteDividendParam {
  assetId: string,
  dividendId: string,
}

export interface AddDividendParam {
  amount: number;
  assetId: string;
  period: Date;
}

interface EarningExtended extends Earning {
  id: string,
  ref: DocumentReference,
}

/** Helper functions */
const createEarning = (
  amount: Earning['amount'],
  period: Date,
): Pick<Earning, 'updatedDateTime' | 'deleted' | 'amount' | 'period'> => {
  const dateNow = firebase.firestore.Timestamp.now();
  return {
    updatedDateTime: dateNow,
    deleted: false,
    period: firebase.firestore.Timestamp.fromDate(period),
    amount,
  };
};

const getEarnings = async (dividendRef: DocumentReference<Dividend>): Promise<EarningExtended[]> => {
  // Query earnings
  const [earningsQueryError, earningsQuery] = await to(bloqifyFirestore.collectionGroup('earnings')
    .where('dividend', '==', dividendRef)
    .get() as Promise<firebase.firestore.QuerySnapshot<Earning>>);

  if (earningsQueryError || !earningsQuery || earningsQuery.empty) {
    throw Error(earningsQueryError?.message || 'There was an error fetching the earnigns');
  }
  return earningsQuery.docs.map((doc): EarningExtended => ({
    ...doc.data(),
    id: doc.id,
    ref: doc.ref,
  }));
};

export default <Module<Vertebra, State>>{
  state: generateState(),
  mutations: {
    [SET_DIVIDENDS](state, { status, payload, operation }: { status: DataContainerStatus, payload?: any, operation: string }): void {
      mutateState(state, status, operation, payload);
    },
  },
  actions: {
    async addDividend(
      { commit }, { amount, assetId, period }: AddDividendParam,
    ): Promise<void> {
      commit(SET_DIVIDENDS, { status: DataContainerStatus.Processing, operation: 'addDividend' });

      const assetRef = bloqifyFirestore.collection('assets').doc(assetId) as DocumentReference<Asset>;
      const newDividendRef = assetRef.collection('dividends').doc() as DocumentReference<Dividend>;

      const [transactionError, transactionSuccess] = await to(bloqifyFirestore.runTransaction(async (transaction): Promise<any> => {
        // Read asset
        const [assetError, assetSnapshot] = await to(transaction.get(assetRef));

        if (assetError || !assetSnapshot || !assetSnapshot.exists) {
          throw Error(assetError?.message || 'This asset does not exist');
        }

        // Query investments
        // eslint-disable-next-line max-len
        const [investmentsQueryError, investmentsQuery] = await to((bloqifyFirestore.collection('investments') as firebase.firestore.CollectionReference<Investment>).where('asset', '==', assetRef).get());

        if (investmentsQueryError || !investmentsQuery || investmentsQuery.empty) {
          throw Error(investmentsQueryError?.message || 'There are no investments for this asset');
        }

        // The total value needed to determine how many euros each investor gets
        const totalValueEuro = assetSnapshot.get('totalValueEuro') as Asset['totalValueEuro'];

        investmentsQuery.forEach(async (investmentSnapshot): Promise<void> => {
          const investorRef = investmentSnapshot.get('investor');

          // Calculate earning amount and round it with 2 digit precision
          const paidEuroTotal = (investmentSnapshot.get('paidEuroTotal') as Investment['paidEuroTotal']) || 0;
          const earningAmount = (paidEuroTotal / totalValueEuro) * amount;
          const roundedEarningAmount = Math.round((earningAmount + Number.EPSILON) * 100) / 100;

          transaction.update(investmentSnapshot.ref, {
            totalEarnings: firebase.firestore.FieldValue.increment(roundedEarningAmount),
            updatedDateTime: firebase.firestore.FieldValue.serverTimestamp(),
          });

          transaction.set(investmentSnapshot.ref.collection('earnings').doc() as DocumentReference<Earning>, {
            amount: roundedEarningAmount,
            period: Timestamp.fromDate(period),
            deleted: false,
            investor: investorRef,
            investment: investmentSnapshot.ref,
            createdDateTime: firebase.firestore.FieldValue.serverTimestamp() as Timestamp,
          });
        });

        transaction.update(assetRef, {
          totalDividendAmount: firebase.firestore.FieldValue.increment(amount),
          updatedDateTime: firebase.firestore.FieldValue.serverTimestamp(),
        });

        transaction.set(newDividendRef, {
          amount,
          deleted: false,
          asset: assetRef,
          period: firebase.firestore.Timestamp.fromDate(period),
          createdDateTime: firebase.firestore.FieldValue.serverTimestamp() as Timestamp,
          updatedDateTime: firebase.firestore.FieldValue.serverTimestamp() as Timestamp,
        });
      }));

      if (transactionError) {
        return commit(SET_DIVIDENDS, {
          status: DataContainerStatus.Error,
          payload: transactionError,
          operation: 'addDividend',
        });
      }

      return commit(SET_DIVIDENDS, {
        status: DataContainerStatus.Success,
        payload: transactionSuccess,
        operation: 'addDividend',
      });
    },
    async updateDividend(
      { commit },
      { newTotalAmount, assetId, dividendId, period }: UpdateDividendParam,
    ): Promise<void> {
      commit(SET_DIVIDENDS, {
        status: DataContainerStatus.Processing,
        operation: 'updateDividend',
      });

      const assetRef = bloqifyFirestore.collection('assets').doc(assetId);
      const dividendRef = assetRef.collection('dividends').doc(dividendId) as DocumentReference<Dividend>;

      const [transactionError, transactionSuccess] = await to(bloqifyFirestore.runTransaction(async (transaction): Promise<any> => {
        // Read dividend
        const [dividendSnapshotError, dividendSnapshot] = await to(transaction.get<Dividend>(dividendRef));

        if (dividendSnapshotError || !dividendSnapshot || !dividendSnapshot.exists) {
          throw Error(dividendSnapshotError?.message || 'There was an error fetching the dividend');
        }

        const oldPeriod = dividendSnapshot.get('period') as Dividend['period'];
        const oldTotalAmount = dividendSnapshot.get('amount');
        const newToOldRatio = newTotalAmount / oldTotalAmount; // => ratio * oldAmount = newAmount

        // Read asset
        const [assetError, assetSnapshot] = await to(transaction.get(assetRef));

        if (assetError || !assetSnapshot || !assetSnapshot.exists) {
          throw Error(assetError?.message || 'There was an error fetching the asset');
        }

        // The difference between the two interest amounts (positive if the amount increased, negative otherwise)
        const amountDifference = newTotalAmount - (dividendSnapshot.get('amount') as Dividend['amount']);

        const earnings = await getEarnings(dividendRef);

        // update earnings
        await Promise.all(earnings.map(async (earning): Promise<void> => {
          let newEarningAmount: number;
          const oldEarningAmount = earning.amount;
          if (moment(oldPeriod.toDate()).isSame(moment(period), 'day')) {
            // same date, means we can adjust the amounts in a simple way
            newEarningAmount = oldEarningAmount * newToOldRatio;
          } else {
            // we should get the distribution at that point in time for now we'll also use the simple way
            newEarningAmount = oldEarningAmount * newToOldRatio;
          }
          const newEarning = createEarning(newEarningAmount, period);
          const investmentRef = bloqifyFirestore.collection('investments').doc(earning.investment.id);
          transaction.update(earning.ref, newEarning);

          // Also update the investment itself
          transaction.update(investmentRef, {
            totalEarnings: firebase.firestore.FieldValue.increment(newEarningAmount - oldEarningAmount),
            updatedDateTime: firebase.firestore.FieldValue.serverTimestamp(),
          });
        }));

        transaction.update(assetRef, {
          totalDividendAmount: firebase.firestore.FieldValue.increment(amountDifference),
          updatedDateTime: firebase.firestore.FieldValue.serverTimestamp(),
        });

        transaction.update(dividendRef, {
          amount: newTotalAmount,
          period: firebase.firestore.Timestamp.fromDate(period),
          updatedDateTime: firebase.firestore.FieldValue.serverTimestamp(),
        });
      }));

      if (transactionError) {
        return commit(SET_DIVIDENDS, {
          status: DataContainerStatus.Error,
          payload: transactionError,
          operation: 'updateDividend',
        });
      }

      return commit(SET_DIVIDENDS, {
        status: DataContainerStatus.Success,
        payload: transactionSuccess,
        operation: 'updateDividend',
      });
    },
    async deleteDividend(
      { commit },
      deleteDividendParameters: DeleteDividendParam,
    ): Promise<void> {
      commit(SET_DIVIDENDS, {
        status: DataContainerStatus.Processing,
        operation: 'deleteDividend',
      });

      const assetRef = bloqifyFirestore.collection('assets').doc(deleteDividendParameters.assetId) as DocumentReference<Asset>;
      const dividendRef = assetRef.collection('dividends').doc(deleteDividendParameters.dividendId) as DocumentReference<Dividend>;

      const [transactionError, transactionSuccess] = await to(bloqifyFirestore.runTransaction(async (transaction): Promise<any> => {
        const [getDividendError, getDividend] = await to(dividendRef.get());

        if (getDividendError) {
          commit(SET_DIVIDENDS, {
            status: DataContainerStatus.Error,
            payload: getDividendError,
            operation: 'deleteDividend',
          });
          return;
        }

        // delete earnings
        const earnings = await getEarnings(dividendRef);
        await Promise.all(earnings.map(async (earning): Promise<void> => {
          // delete the earning
          const deletedEarning: Partial<Earning> = {
            deleted: true,
          };
          transaction.update(earning.ref, deletedEarning);

          // Also update the investment itself
          const investmentRef = bloqifyFirestore.collection('investments').doc(earning.investment.id);
          transaction.update(investmentRef, {
            totalEarnings: firebase.firestore.FieldValue.increment(-earning.amount),
            updatedDateTime: firebase.firestore.FieldValue.serverTimestamp(),
          });
        }));

        const dividend: Dividend = getDividend?.data() as Dividend;

        // update the asset
        transaction.update(assetRef, {
          totalDividendAmount: firebase.firestore.FieldValue.increment(-dividend.amount),
          updatedDateTime: firebase.firestore.FieldValue.serverTimestamp(),
        });
        // update Dividend itself
        transaction.update(dividendRef, {
          updatedDateTime: firebase.firestore.FieldValue.serverTimestamp(),
          deleted: true,
        });
      }));

      if (transactionError) {
        return commit(SET_DIVIDENDS, {
          status: DataContainerStatus.Error,
          payload: transactionError,
          operation: 'deleteDividend',
        });
      }

      return commit(SET_DIVIDENDS, {
        status: DataContainerStatus.Success,
        payload: transactionSuccess,
        operation: 'deleteDividend',
      });
    },
  },
};
